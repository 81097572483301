import React from 'react'
import Layout from '../components/Layout';
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>404</h1>
      <p>
        Page not found. 
      </p>
      <p>
      <Link to="/" style={{
        color: '#276466',
        fontWeight: 'bold'
      }}>
        Go Back
      </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
